exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/About.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-accessibility-tsx": () => import("./../../../src/pages/Accessibility.tsx" /* webpackChunkName: "component---src-pages-accessibility-tsx" */),
  "component---src-pages-blog-artificial-intel-ircc-tsx": () => import("./../../../src/pages/blog/artificial-intel-IRCC.tsx" /* webpackChunkName: "component---src-pages-blog-artificial-intel-ircc-tsx" */),
  "component---src-pages-blog-judicial-review-tsx": () => import("./../../../src/pages/blog/judicial-review.tsx" /* webpackChunkName: "component---src-pages-blog-judicial-review-tsx" */),
  "component---src-pages-blog-study-permits-india-tsx": () => import("./../../../src/pages/blog/study-permits-india.tsx" /* webpackChunkName: "component---src-pages-blog-study-permits-india-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/Blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-business-tsx": () => import("./../../../src/pages/Business.tsx" /* webpackChunkName: "component---src-pages-business-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/Careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-companies-tsx": () => import("./../../../src/pages/Companies.tsx" /* webpackChunkName: "component---src-pages-companies-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/Contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-disclaimer-tsx": () => import("./../../../src/pages/Disclaimer.tsx" /* webpackChunkName: "component---src-pages-disclaimer-tsx" */),
  "component---src-pages-families-tsx": () => import("./../../../src/pages/Families.tsx" /* webpackChunkName: "component---src-pages-families-tsx" */),
  "component---src-pages-immigration-tsx": () => import("./../../../src/pages/Immigration.tsx" /* webpackChunkName: "component---src-pages-immigration-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-investment-tsx": () => import("./../../../src/pages/Investment.tsx" /* webpackChunkName: "component---src-pages-investment-tsx" */),
  "component---src-pages-investors-tsx": () => import("./../../../src/pages/Investors.tsx" /* webpackChunkName: "component---src-pages-investors-tsx" */),
  "component---src-pages-our-team-tsx": () => import("./../../../src/pages/OurTeam.tsx" /* webpackChunkName: "component---src-pages-our-team-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/Privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/Services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-why-golden-maple-tsx": () => import("./../../../src/pages/WhyGoldenMaple.tsx" /* webpackChunkName: "component---src-pages-why-golden-maple-tsx" */)
}

